import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { Spinner } from '@ifca-ui/neumorphism'
import '@ifca-ui/neumorphism/src/styles/root.scss'
import theme from '@ifca-ui/neumorphism/src/theme'
import { ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'components/guestapp.scss'
import { InstallBanner } from 'components/InstallBanner/InstallBanner'
import StyledSnackBar from 'components/SnackBar/StyledSnackBar'
import AppContext from 'containers/Context/Context'
import { RootInitialState, RootReducer } from 'containers/Context/Reducer'
import { useInstallPrompt } from 'containers/hooks/useInstallPrompt'
import React, { FC, useEffect, useReducer, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from '../Routes'
import { ScrollToTop } from '../Routes/ScrollToTop'
import { Client } from './Client'

type Props = {}

const App: FC<Props> = () => {
  const [RootState, RootDispatch] = useReducer(RootReducer, RootInitialState)
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    RootDispatch({
      type: 'CloseSnackBar',
      payload: {},
    })
  }
  const { promptable, promptToInstall, isInstalled } = useInstallPrompt()
  const [isVisible, setVisible] = useState(false)

  const hide = () => setVisible(false)
  useEffect(() => {
    if (prompt) {
      setVisible(true)
    }
  }, [prompt])
  if (process.env.NODE_ENV !== 'development') console.log = () => {}
  return (
    <>
      {RootState?.OnLoading && <Spinner />}
      <AppContext.Provider value={{ RootState, RootDispatch }}>
        <ApolloProvider client={Client}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <BrowserRouter>
                <ScrollToTop />
                <Routes />

                {promptable && !isInstalled ? (
                  <InstallBanner
                    ButtonOption={{
                      section: {
                        props: {
                          style: { display: !isVisible ? 'none' : null },
                        },
                      },
                      option1: {
                        props: {
                          onClick: () => hide(),
                        },
                      },
                      option2: {
                        props: {
                          onClick: () => promptToInstall(),
                        },
                      },
                    }}
                  />
                ) : null}
                <StyledSnackBar
                  autoHideDuration={4000}
                  open={RootState.OnSnackBar.Open}
                  message={RootState.OnSnackBar.Message}
                  onClose={handleClose}
                  onClick={() =>
                    RootState.OnSnackBar.onClick() ||
                    RootDispatch({
                      type: 'CloseSnackBar',
                      payload: {},
                    })
                  }
                />
              </BrowserRouter>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ApolloProvider>
      </AppContext.Provider>
    </>
  )
}

export default App