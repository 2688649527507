import { CopyRight, MainHeader, MainWrapper } from '@ifca-ui/neumorphism'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { PUBLIC_PATH } from 'containers/Module/Auth/Routes'
import { useLoggedInGuestProfileLazyQuery } from 'generated/graphql'
import React, { ReactNode, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import HotelX from 'assets/images/app-logo-b.svg'
import jwtDecode from 'jwt-decode'


interface Props {
  children?: ReactNode
}

export const GuestLayout = ({ children }: Props) => {
  const history = useHistory()
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
const token = params?.parameter
const item =  token ? jwtDecode(token) : ''


const HotelID = item?.HotelID
const BookingID = item?.BookingID
const RegistrationID = item?.RegistrationID

useEffect(()=>{
  // if url with params will not run (first time) otherwise API runs and got kicked
if(HotelID && BookingID && RegistrationID){

   console.log('11111')

}
else 
{
  console.log('22222')
  loggedInGuestProfileinit()
}
},[])
  

let isInValiduser = false
  const [loggedInGuestProfileinit,{
    data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  }] = useLoggedInGuestProfileLazyQuery({
    fetchPolicy: 'network-only',
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message:
              message === "Session expired, please login again."
                ? 'Token has expired'
                : message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
     
        if (message === "Session expired, please login again.") {
          isInValiduser = true
          history.push(PUBLIC_PATH.NOT_FOUND_404)
          }
          
        
      })
    },
  })

  useEffect(()=>{
    if(isInValiduser){
    RootDispatch({
      type: 'CloseSnackBar',
      payload: {},
    })
  history.push(PUBLIC_PATH.NOT_FOUND_404)
  }
  },[loggedInGuestProfile])
  return (
    <MainWrapper>
      {' '}
      <MainHeader
        sections={{
          header: RootState?.HeaderSection?.header,
          left: RootState?.HeaderSection?.left,
          right: RootState?.HeaderSection?.right,
        }}
      />
      {children}
      <CopyRight title={<img src={HotelX} alt="logo" width={50} />} />
    </MainWrapper>
  )
}